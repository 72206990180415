.alert {
  margin-bottom: 24px;
}

.antFormItem {
  margin-bottom: 12px;
}

.backLink {
  width: 100%;
  display: flex;
  justify-content: center;
}

.error {
  color: #f5222d;
  line-height: 1;
  margin-top: 7px;
  &::first-letter {
    text-transform: capitalize;
  }
}

.errorMessageWrapper {
  display: flex;
  justify-content: center;
  line-height: 1.3;
  .errorMessage {
    background-color: #ffefee;
    border: 1px solid #ffaeab;
    border-radius: 3px;
    border-radius: 3px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
  }
}

@card-actions-background: #f5f8fa;@card-head-background: #ffffff;@primary-color: #375784;@body-background: #F5F8FA;@menu-highlight-color: #375784;@highlight-color: #D81F7B;@heading-color: #375784;@normal-color: #898989;@layout-header-background: #00BABC;@layout-header-height: 64px;@layout-header-padding: 0 50px;@border-radius-base: 3px;@table-row-hover-bg: @body-background;@input-height-base : 32px;@btn-height-base: 32px;@btn-padding-base: 0 15px;@font-family-no-number: -apple-system, BlinkMacSystemFont, sans-serif;@btn-danger-bg: #fff;@card-head-padding: 24px;@card-padding-wider: 24px;@font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;