.LoginCard {
  .success {
    text-align: center;
  }
  .listWrapper {
    margin-top: 1rem;
    margin-bottom: 2rem;
    .list {
      margin-bottom: 2rem;
    }
  }
  .connect {
    text-align: center;
    border-bottom: 2px solid #d9d9d9;
    margin-bottom: 0rem;
    padding-bottom: 1rem;
  }
  .imageWrapper {
    display: flex;
    justify-content: center;
    a:first-of-type {
      img {
        margin-right: 1rem;
      }
    }
    img {
      height: 50px;
      max-height: 100%;
    }
  }
}

@card-actions-background: #f5f8fa;@card-head-background: #ffffff;@primary-color: #375784;@body-background: #F5F8FA;@menu-highlight-color: #375784;@highlight-color: #D81F7B;@heading-color: #375784;@normal-color: #898989;@layout-header-background: #00BABC;@layout-header-height: 64px;@layout-header-padding: 0 50px;@border-radius-base: 3px;@table-row-hover-bg: @body-background;@input-height-base : 32px;@btn-height-base: 32px;@btn-padding-base: 0 15px;@font-family-no-number: -apple-system, BlinkMacSystemFont, sans-serif;@btn-danger-bg: #fff;@card-head-padding: 24px;@card-padding-wider: 24px;@font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;