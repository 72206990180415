.main {
  width: 300px;
  margin: 0 auto;
}

.title {
  text-align: center;
}

.submit {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 0;
}

.content {
  padding: 32px;
  display: flex;
  background: white;
  border: 1px solid #898989;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
}

.top {
  text-align: center;
  margin-bottom: 30px;
}

.header {
  height: 44px;
  line-height: 44px;
}

.header > a {
  text-decoration: none;
}

.logo {
  vertical-align: top;
  max-width: 288px;
}
