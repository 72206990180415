@import '~antd/lib/style/themes/default.less';
@import '../../config/variables.less';

.main {
  // width: 300px;
  margin: 0 auto;
  @media screen and (max-width: @screen-sm) {
    width: 95%;
  }
}

.content {
  width: 60%;
  padding: 50px;
  display: flex;
  background: white;
  border: 1px solid @keet-grey;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
}

.top {
  text-align: center;
  margin-bottom: 30px;
}

.header {
  height: 44px;
  line-height: 44px;
  a {
    text-decoration: none;
  }
}

.logo {
  max-width: 500px;
  display: block;
  margin: 0 auto;
}

.message {
  text-align: 'center';

  .title {
    text-align: center;
  }
}

@card-actions-background: #f5f8fa;@card-head-background: #ffffff;@primary-color: #375784;@body-background: #F5F8FA;@menu-highlight-color: #375784;@highlight-color: #D81F7B;@heading-color: #375784;@normal-color: #898989;@layout-header-background: #00BABC;@layout-header-height: 64px;@layout-header-padding: 0 50px;@border-radius-base: 3px;@table-row-hover-bg: @body-background;@input-height-base : 32px;@btn-height-base: 32px;@btn-padding-base: 0 15px;@font-family-no-number: -apple-system, BlinkMacSystemFont, sans-serif;@btn-danger-bg: #fff;@card-head-padding: 24px;@card-padding-wider: 24px;@font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;